import React, { FunctionComponent } from 'react'
import { Container, Info, NoWrap } from './style'

export const Footer: FunctionComponent<{}> = () => {
  return (
    <Container>
      <Info>
        <NoWrap>&copy; {new Date(Date.now()).getFullYear()}</NoWrap>{' '}
        <NoWrap>DL Ford</NoWrap>
      </Info>
      <Info>Thanks for stopping by :)</Info>
    </Container>
  )
}
